import { ReactComponent as IconNote } from '../assets/icons/icon-note.svg';

export const PROJECTS = [
  {
    id: '1',
    title: 'Tranströmerbiblioteket',
    address: 'Medborgarplatsen',
    description:
      <article>
        <p>Södermalm var en fast punkt i Tomas Tranströmers barndom och det här är en vandring med nedslag i hans Södermalm.</p>
        
        <p>Promenaden tar ungefär 45 min och vi besöker och berättar om platser som på något sätt anknyter till Tomas Tranströmer och hans författarskap. Du kommer också att få lyssna till poesi av Tranströmer.</p>

        <section className="poem">
          <p>
            Rummets enda fönster vetter mot någonting annat:<br />
            &nbsp;&nbsp;&nbsp;Det Vilda Torget, <br/>
            en mark som jäser, en stor skälvande yta, ibland full<br />
            &nbsp;&nbsp;&nbsp;av folk och ibland öde.
          </p>
        </section>
        
        <p>Så bra den här strofen ur dikten <span className="italic">Carillon</span> från samlingen <span className="italic">Det vilda torget</span> (1983) passar in på Medborgarplatsen!</p>
 
        <p>Medborgarplatsen på Södermalm i Stockholm, fem minuters promenad från Tomas Tranströmers hem. En plats som han ofta passerade som barn och ungdom, på väg till Medborgarplatsens bibliotek, där han var en trogen besökare. </p>

        <p>I sin självbiografiska bok <span className="italic">Minnena ser mig</span> beskriver Tranströmer sina nästan dagliga biblioteksbesök och han minns den för honom behagliga blandningen av lukten av klor från Forsgrénska badet och lukten av böcker från biblioteket. När barnavdelningens utbud inte längre räckte till för den vetgirige Tomas sökte han sig vidare till litteraturen på vuxenavdelningen. Men där tog det stopp. De på den tiden stränga bibliotekarierna lät honom inte låna där. Detta löste sig genom att Tomas fick låna sin morbrors lånekort och ytterligare en dimension av litteratur öppnades för honom. Det som lockade honom var framför allt böcker om historia och geografi. Här kunde han fördjupa sig och få utlopp för sin fantasi och sina dagdrömmar.</p>
  
        <p>Då låg biblioteket i den högra flygeln i det nybyggda Medborgarhuset med de två stora trapporna och väggar av gult tegel. Här inrymdes både hörsal, bad och bibliotek. Vuxenavdelningen låg en trappa upp med ingång från torget, och barnavdelningen på entréplan med ingång från Folkungagatan. Huset, som invigdes 1939 byggdes med ledorden; kropp, själ och bildning. Biblioteket låg tidigare på Götgatan 68 i Bäckströmska huset.</p>

        <p>Nu går vi vidare mot Södra Latin. Plats nummer 2.</p>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/medborgarhuset_bibliotek_Lennart_af_Petersens_sthlms_stadsmuseum.jpg',
      caption: 'Biblioteket vid Medborgarplatsen, 1943',
      ref: 'Fotograf: Petersens, Lennart af (1913-2004) — 1943 — Objekt-ID: Stockholms stadsmuseum, Fotonummer FA 33840'
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-1.mp3',
    },
    pos: {
      lat: '59.31470902656806',
      long: '18.072567881036857',
    },
    // pos: {
    //   lat: '59.294241',
    //   long: '17.941114',
    // },
    // pos: {
    //   lat: '59.310676572959224',
    //   long: '18.083231678463157',
    // },
    checked: false
  },
  {
    id: '2',
    title: 'Södra Latin',
    address: 'Skaraborgsgatan 9',
    description:
      <article>
        <p>Södra Latin ligger högt beläget på Södermalm. Den nuvarande skolbyggnaden invigdes 1891 under namnet Högre Allmänna Läroverket för gossar på Södermalm. Först 1961 tilläts också flickor att gå på skolan och 1971 fick skolan namnet Södra Latins gymnasium.</p>

        <p>Här började den elvaårige Tomas realskolan 1942 efter avklarad folkskola. Efter realskolan följde gymnasiet och 1946 började Tomas på Södra Latins latinlinje. <br/>
        Vänskapsband formades och han blev nära vän med bland annat Bo Grandien och Per Wästberg. Känslan och stämningen på Södra Latin på den tiden återspeglas i Alf Sjöbergs film <span className="italic">Hets</span> (1944) som är inspelad på skolan. Eleverna som gick i skolan under inspelningen fungerade som statister i vissa scener.</p>

        <p>Tomas och hans kamratgäng hade en stark motvilja mot sport och gymnastik men ägnade gärna tid åt läsning, samtal och att skriva i Södra Latins skoltidning Loke. Här fick Tranströmer med fyra dikter 1948. Och fem dikter publicerades i den Bonnierstödda gymnasisttidskriften <span className="italic">Medan lagrarna gro</span> 1949.</p>

        <p>Tranströmers skrivande började på allvar ta form under gymnasieåren och han ansågs vara en stor begåvning; påläst och kunnig gällande både politik och samhällsfrågor.</p>

        <p>Lärarna var ett kapitel för sig och det rådde en oenighet gällande modernistisk poesi kontra att skriva enligt de klassiska versmåtten. Särskilt intryck på Tranströmer gjorde lektorn i latin och grekiska. Och trots vissa kontroverser tog ändå Tranströmer intryck gällande att skriva på klassisk vers och detta inspirerade honom i debuten <span className="italic">17 dikter</span>.</p>

        <p>1950 tog Tranströmer studentexamen och påbörjade efter det akademiska studier och tog sedermera yrkesexamen som psykolog.</p>

        <p>1954, 23 år gammal, debuterade han med diktsamlingen <span className="italic">17 dikter</span> som hyllades och ansågs vara en sällsynt mogen debut. Den inleds med den mycket kända raden; “Uppvaknandet är ett fallskärmshopp från drömmen” ur diktsamlingens första dikt <span className="italic">Preludium</span>.</p>

        <p>Trots Tranströmers litterära framgångar ägnade han sig aldrig åt skrivandet på heltid utan fortsatte att arbeta som psykolog på halvtid.</p>

        <p>Men inte kunde väl någon där och då, på 1940- och 50-talets Södermalm, ana att skolkamraten Tomas drygt sextio år senare skulle tilldelas Nobelpriset i litteratur eller att han skulle få sitt barndoms bibliotek uppkallat efter sig och ett litterärt pris - Tranströmerpriset, instiftat i hans namn av Västerås stad där han var verksam som psykolog i många år. Södra Latin har valt att hylla sin tidigare elev genom att instifta ett Tranströmerstipendium.</p>

        <section className="poem">
        <h4 className="poem--title">Stenarna</h4>
          <p>
            Stenarna som vi kastat hör jag<br />
            falla, glasklara genom åren. I dalen<br />
            flyger ögonblickets förvirrade<br />
            handlingar skränande från<br />
            trädtopp till trädtopp, tystnar<br />
            i tunnare luft än nuets, glider<br />
            som svalor från bergstopp<br />
            till bergstopp tills de<br />
            nått de yttersta platåerna<br />
            utmed gränsen för varat. Där faller<br />
            alla våra gärningar<br />
            glasklara<br />
            mot ingen botten<br />
            utom oss själva.
          </p>
        </section>

        <section className="poem">
          <h4 className="poem--title">Storm</h4>
          <p>
            Plötsligt möter vandraren här den gamla<br />
            jätteeken, lik en förstenad älg med<br />
            milsvid krona framför septemberhavets<br />
            <span className="is-text-center">svartgröna fästning.</span>
          </p>

          <p>
            Nordlig storm. Det är i den tid när rönnbärs-<br />
            klasar mognar. Vaken i mörkret hör man<br />
            stjärnbilderna stampa i sina spiltor<br />
            <span className="is-text-center">högt över trädet.</span>
          </p>
        </section>

        <p>Dikterna <span className="italic">Stenarna</span> respektive <span className="italic">Storm</span> är hämtade från Tranströmers debutsamling <span className="italic">17 Dikter</span>.</p>

        <p>Nu går vi vidare mot Katarina Kyrkbacke. Plats nummer 3.</p>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/Sodra_Latin_Herman_Ronninger_sthlms_stadsmuseum.jpg',
      caption: 'Södra Latins nye rektor Ekman med skolans elever, 24 augusti 1950',
      ref: 'Fotograf: Ronninger, Herman (1895-1976) — 24 augusti 1950 — Objekt-ID: Stockholms stadsmuseum, Fotonummer: SvD 25999'
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-2.mp3',
    },
    pos: {
      lat: '59.316716766826275',
      long: '18.07003559769613',
    },
    // pos: {
    //   lat: '59.294488',
    //   long: '17.939719'
    // }
    // pos: {
    //   lat: '59.310478',
    //   long: '18.082143'
    // },
    checked: false
  },
  {
    id: '3',
    title: 'Katarina Kyrka',
    address: 'Katarina Kyrkobacke 7A',
    description:
      <article>
        <p>För Tomas Tranströmer var musiken en livslång kärlek och det märks inte minst i hans dikter. </p>

        <p>Som tonåring började han spela piano och övervägde att satsa på musiken. Han lyssnade mycket på musik och fick följa med sin mor på konserter. I en skoluppsats från 1948 skriver han: “För mig är musiken inte ett intresse utan en del av mig själv, och en mycket viktig del.” och musikintresset följde honom livet igenom.</p>

        <p>När Tomas Tranströmer 1990 drabbades av en stroke miste han till viss del sin tal- och rörelseförmåga på främst höger sida. Efter det blev musiken och pianospelandet allt mer viktigt för honom. Tranströmer fortsatte att öva och spela piano, nu med sin vänstra hand. I musiken fanns inga hinder utan en känsla av frihet.</p>

        <p>Det finns flera hundra pianostycken komponerade för vänsterhand, varav några nyskrivna till Tranströmers ära. Han är också en mycket tonsatt poet.</p>

        <p>Tranströmer blev 2011 hedersledamot av Kungliga Musikaliska Akademien.</p>

        <section className="poem">
          <h4 className="poem--title">C-dur (Den halvfärdiga himlen, 1962)</h4>
          <p>
            När han kom ner på gatan efter kärleksmötet<br />
            virvlade snö i luften.<br />
            Vintern hade kommit<br />
            medan de låg hos varann.<br />
            Natten lyste vit.<br />
            Han gick fort av glädje.<br />
            Hela staden sluttade.<br />
            Förbipasserande leenden -<br />
            alla log bakom uppfällda kragar.<br />
            Det var fritt!<br />
            Och alla frågetecken började sjunga om Guds tillvaro.<br />
            Så tyckte han.
          </p>
          <p>
            En musik gjorde sig lös<br />
            och gick i yrande snö<br />
            med långa steg.<br />
            Allting på vandring mot ton C.<br />
            En darrande kompass riktad mot C.<br />
            En timme ovanför plågorna.<br />
            Det var lätt!<br />
            Alla log bakom uppfällda kragar.
          </p>
        </section>

        <p>Nu går vi vidare mot Solrosparken. Plats nummer 4.</p>
        <div className="Box--green">
          <IconNote/>
          <p>Tranströmer spelar <span className="italic">Zdenko Fibich: Andante ur Stimmungen, Eindrücke und Erinnerungen op. 47</span></p>
        </div>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/katarina_kyrkobacke_sthlms_stadsmuseum.jpeg',
      caption: 'Kv. Häckelfjäll från Fiskargatan mot Katarina kyrka, 1961',
      ref: 'Fotograf: Lindgren, Herbert (1919-1987) — 1961 — Objekt-ID: Stockholms stadsmuseum, Fotonummer Fg 9498',
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-3.mp3',
    },
    pos: {
      lat: '59.317409858810656',
      long: '18.077973595033242'
    },
    checked: false
  },
  {
    id: '4',
    title: 'Solrosparken',
    address: 'Nytorgsgatan',
    description:
      <article>
        <p>Tranströmer är en av få svenska poeter som har haft ett inflytande på världslitteraturen. Han har översatts till ett sextiotal språk.</p>

        <p>Att dikterna först blev översatta till engelska öppnade för översättningar till fler språk. Den amerikanske norskättlingen, poeten och översättaren Robert Bly hade en viktig roll genom sina kontakter inom den amerikanska universitetsvärlden där Tranströmer fick möjlighet till både uppläsningar och att medverka i skrivarkurser. </p>

        <p>Tranströmer och Bly lärde känna varandra på 1960-talet. De översatte och tolkade inte bara varandras dikter, de utvecklade också en vänskap. Den mesta översättningen skedde brevledes men de två poeterna träffades också när det var möjligt. Ibland blev det krångligt och svårt att exakt förklara nyanserna i ett ord i breven, då ritade de istället för att få varandra att förstå.</p>

        <p>Tranströmer har alltid sett det som viktigt att ha en nära kontakt med sina översättare och vara delaktig i deras arbete. Han har uttryckt en tacksamhet över att hans dikter haft möjlighet att färdas ut i världen och blivit lästa tack vare översättningarna. </p>

        <p>Men kontakten med Robert Bly var något unikt och under åren utvecklade de en stark tillit till varandra och de arbetade tillsammans utan att känna konkurrens och de stimulerade varandras översättande och skrivande.</p>

        <p>Deras brevväxling mellan 1964 - 1990 finns utgiven som bok; <span className="italic">Air Mail</span>, och det är en underbar läsning om hur poesi överförs mellan olika språk samt en vänskap på distans.</p>

        <p>Inte sällan brukar frågan ställas varför Tranströmer är så läst, så älskad, och så citerad. Det kanske är så att det finns en Tranströmerdikt för varje sinnesstämning i livet? Kanske är det dikternas tidlöshet, det existentiella som finns hos varje människa, och att dikterna ger en ingång till detta och att läsaren inbjuds att göra egna tolkningar. </p>

        <p>2011 jublade många lyrikälskare när Tranströmer tilldelades Nobelpriset i litteratur.<br />Nobel-motiveringen löd: “För att han i förtätade, genomlysta bilder ger oss ny tillgång till det verkliga”. Självklart skulle detta inte varit möjligt om inte dikterna blivit översatta och tillgängliga på så många språk.</p>

        <section className="poem">
          <h4 className="poem--title">Madrigal (ur Levande och döda 1989)</h4>
          <p className="is-text-justify">
            Jag ärvde en mörk skog dit jag sällan går. Men det kommer en dag när de döda och levande byter plats. Då sätter sig skogen i rörelse. Vi är inte utan hopp. De svåraste brotten förblir ouppklarade trots insats av många poliser. På samma sätt finns någonstans i våra liv en stor ouppklarad kärlek. Jag ärvde en mörk skog men idag går jag i en annan skog, den ljusa. Allt levande som sjunger slingrar viftar och kryper! Det är vår och luften är mycket stark. Jag har examen från glömskans universitet och är lika tomhänt som skjortan på tvättstrecket.
          </p>
        </section>

        <p>Nu går vi vidare mot Katarina norra skola. Plats nummer 5.</p>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/solrosparken_utsikt_sthlms_stadsmuseum.jpeg',
      caption: 'Amerikanska flottans besök, 1946',
      ref: 'Fotograf:Petersens, Lennart af (1913-2004) — 1946 — Objekt-ID: Stockholms stadsmuseum, Fotonummer F 43465',
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-4.mp3',
    },
    // pos: {
    //   lat: '59.31763536202682',
    //   long: '18.08023179467842',
    // }
    pos: {
      lat: '59.31769848424047',
      long: '18.080871361957204'
    },
    checked: false
  },
  {
    id: '5',
    title: 'Katarina Norra Skola',
    address: 'Katarina Östra Kyrkogårdsgränd',
    description:
      <article>
        <p>I slutet av 1930-talet började Tranströmer i Katarina norra folkskola. Åren i folkskolan passade honom inte särskilt bra. Det var också en orolig tid med ett överhängande hot om krig och evakuering.</p>

        <p>Stämningen i skolan var ganska tuff. Läsa och skriva kunde Tomas redan. Men det fanns stunder av magi. Ibland fick han följa med en av sina lärare och hämta fram de fascinerande skolplanscherna från förrådet. Genom dem öppnades nya spännande världar vilket han tyckte mycket om. Han försökte själv återskapa planscherna hemma. Han längtade efter att skoldagarna skulle ta slut så att han kunde ägna sig åt alla sina intressen. Tomas var en flitig besökare på Riksmuseet (idag Naturhistoriska riksmuseet) vars samlingar fascinerade honom djupt.</p>

        <p>I elvaårsåldern började Tomas själv att samla insekter, i synnerhet skalbaggar och fjärilar, och detta intresse kunde han helt och fullt få låta blomma ut om somrarna på ön Runmarö i Stockholms skärgård.</p>

        <p>Runmarö var för Tranströmer en oerhört viktig plats. Liksom Södermalm, var det ett känslomässigt hem som betydde mycket för honom i både liv och dikt.</p>

        <p>Platsen innebar natur, frihet och inspiration. På upptäcktsfärderna samlade och katalogiserade han sina insekter. “Jag lärde mig att marken levde, att det fanns en oändligt stor krypande och flygande värld som levde sitt eget liv utan att bry sig det minsta om oss” (ur <span className="italic">Minnena ser mig</span>).  </p>

        <p>På sin 80-årsdag 2011 fick Tranströmer en gotländsk tornbagge uppkallad efter sig - Tranströmers tornbagge, på latin <span className="italic">mordellistena transtroemeriana</span>.</p>

        <p>På Runmarö fanns också morfars hus, Det blå huset. Morfar Carl Helmer Westerberg var pensionerad lots och bodde inte långt från Tomas och modern på Södermalm. Morfadern var en mycket betydelsefull person för Tomas under hans unga år.</p>

        <section className="poem">
          <h4 className="poem--title">Det blå huset, ur Det vilda torget (1983)</h4>
          <p>
            Det är en natt med strålande sol. Jag står i den täta skogen och ser bort mot mitt hus med sina disblåa väggar, som om jag vore nyligen död och såg huset från en ny vinkel.<br />
            &nbsp;&nbsp;&nbsp;Det har stått mer än åtti somrar. Dess trä är impregnerat med fyra gånger glädje och tre gånger sorg. När någon som bott i huset dör målas det om. Den döda personen målar själv, utan pensel, inifrån..<br />
            &nbsp;&nbsp;&nbsp;På andra sidan är det öppen terräng. Förr en trädgård, nu förvildad. Stillastående brottsjöar av ogräs, pagoder av ogräs, framvällande text, upanishader av ogräs, en vikingaflotta av ogräs, drakhuvuden, lansar, ett ogräsimperium!<br />
            &nbsp;&nbsp;&nbsp;Över den förvildade trädgården flaxar skuggan av en bumerang som kastas gång på gång. Det har samband med en som bodde i huset långt före min tid. Nästan ett barn. En impuls utgår från honom, en tanke, en viljetanke: &#xbb;skapa...rita...&#xab; För att hinna ut ur sitt öde.<br />
            &nbsp;&nbsp;&nbsp;Huset liknar en barnteckning. En ställföreträdande barnslighet som växte fram därför att någon alltför tidigt avsade sig uppdraget att vara barn. Öppna dörren, stig in! Här inne är oro i taket och fred i väggarna. Över sängen hänger en amatörtavla, föreställande ett skepp med sjutton segel, fräsande vågkammar och en vind som den förgyllda ramen inte kan hejda.<br />
            &nbsp;&nbsp;&nbsp;Det är alltid så tidigt här inne, det är före vägskälen, före de oåterkalleliga valen. Tack för det här livet! Ändå saknar jag alternativen. Alla skisser vill bli verkliga.<br />
            &nbsp;&nbsp;&nbsp;En motor på vattnet långt borta tänjer ut sommarnattens horisont. Både glädje och sorg sväller i daggens förstoringsglas. Vi vet det egentligen inte, men anar det: det finns ett systerfartyg till vårt liv, som går en helt annan trad. Medan solen brinner bakom öarna.
          </p>
        </section>

        <p>Nu går vi vidare mot Folkungagatan 57. Plats nummer 6.</p>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/katarina_norra_skola_sthlms_stadsmuseum.jpeg',
      caption: 'Katarina Norra folkskola, 1966',
      ref: 'Fotograf: Fredriksson, Göran H — 1966 — Objekt-ID: Stockholms stadsmuseum, Fotonummer F 79253'
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-5.mp3',
    },
    pos: {
      lat: '59.31588792548179',
      long: '18.07925385314544',
    },
    checked: false
  },
  {
    id: '6',
    title: 'Folkungagatan 57',
    address: 'Folkungagatan 57',
    description:
      <article>
        <p>Här på den livliga Folkungagatan mitt på Södermalm bland de många butikerna och biograferna bodde Tomas med modern Helmy. Hon arbetade som folkskolelärarinna på Östermalm och promenerade fram och tillbaka till arbetet varje dag. Helmy levde ensam med Tomas. Morfar Carl Helmer som bodde i närheten fanns till hands i Tomas unga år och de trivdes väldigt bra ihop.</p>

        <p>I tvårummaren på våning fyra, Folkungagatan 57 fanns en stor gästfrihet. Ofta samlades släkt och vänner där. Även Tomas vänner från Södra Latin var välkomna och de lyssnade på musik och pratade tills timmen blev sen.</p>

        <p>I huset på Folkungagatan 57 levde en brokig skara människor och mamma Helmy sträckte ofta ut en hjälpande hand om någon i huset behövde det. Södermalm var på 1930- och 40-talet en arbetarstadsdel - men också bohemer, konstnärer och lägre medelklass levde och bodde här.</p>

        <p>Utanför porten finns som en hyllning till Tomas Tranströmers författargärning, idag en litterär skylt med dikten <span className="italic">Från vintern 1947</span> ur diktsamlingen <span className="italic">Sanningsbarriären</span>.</p>
        <section className="poem">
          <h4 className="poem--title">Från vintern 1947</h4>
          <p>
            Om dagarna i skolan den dova myllrande fästningen.<br />
            I skymningen gick jag hem under skyltarna.<br />
            Då kom viskningen utan läppar:  &#xbb;Vakna sömngångare!&#xab;<br />
            och alla föremål pekade mot Rummet.
          </p>
          <p>
            Femte våningen, rummet mot gården. Lampan brann<br />
            i en cirkel av skräck alla nätter.<br />
            Jag satt utan ögonlock i sängen och såg<br />
            bildband bildband med de sinnessjukas tankar.<br />
          </p>
          <p>
            Som om det var nödvändigt...<br />
            Som om den sista barndomen slogs sönder<br />
            för att kunna passera genom gallret.<br />
            Som om det var nödvändigt...<br />
          </p>
          <p>
            Jag läste i böcker av glas men såg bara det andra:<br />
            fläckarna som trängde fram genom tapeterna.<br />
            Det var de levande döda<br />
            som ville ha sina porträtt målade!<br />
          </p>
          <p>
            Till gryningen då sophämtarna kom<br />
            och slamrade med plåtkärlen där nere<br />
            bakgårdens fridfulla grå klockor<br />
            som ringde mig till sömns.<br />
          </p>
        </section>
        <section className="poem">
          <h4 className="poem--title">Hemligheter på vägen</h4>
          <p>
            Dagsljuset träffade ansiktet på en som sov.<br />
            Han fick en livligare dröm<br />
            men vaknade ej.
          </p>
          <p>
            Mörkret träffade ansiktet på en som gick<br />
            bland de andra i solens starka<br />
            otåliga strålar.
          </p>
          <p>
            Det mörknade plötsligt som av ett störtregn.<br />
            Jag stod i ett rum som rymde alla ögonblick -<br />
            ett fjärilsmuseum.
          </p>
          <p>
            Och ändå solen lika stark som förut.<br />
            Dess otåliga penslar målade världen.
          </p>
        </section>
        <p>ur <span className="italic">Hemligheter på vägen</span>, hämtad från diktsamlingen med samma namn. Innan det dikten <span className="italic">Från vintern 1947</span> ur diktsamlingen <span className="italic">Sanningsbarriären</span>. </p>

        <p>Nu går vi vidare mot Tranströmerbiblioteket. Plats nummer 7.</p>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/folkungagatan_sthlms_stadsmuseum.jpeg',
      caption: 'Folkungagatan österut, 1963',
      ref: 'Fotograf: Trapp, Håkan (1938-1967) — 1963  — Objekt-ID: Spårvägsmuseet 2108-968',
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-6.mp3',
    },
    pos: {
      lat: '59.314602462628656',
      long: '18.075794714022052',
    },
    checked: false
  },
  {
    id: '7',
    title: 'Tranströmerbiblioteket',
    address: 'Medborgarplatsen',
    description:
      <article>
        <p>Välkomna tillbaka till Medborgarplatsen, Medborgarhuset och Tranströmerbiblioteket!</p>

        <p>År 2014 fick Medborgarplatsens bibliotek det nya namnet Tranströmerbiblioteket efter ett politiskt beslut. Detta som ett led i hyllningarna till stadens och stadsdelen Södermalms Nobelpristagare i litteratur.</p>

        <p>Tomas Tranströmer ville inte genom bibliotekets namnbyte sättas på piedestal eller ikoniseras utan han ville att hans författarskap skulle få finnas och leva i ett poetiskt sammanhang, ett uppdrag biblioteket nu har att förvalta.</p>

        <p>Tranströmers författarskap genomsyrar verksamheten i bibliotekets tre avdelningar; barn, ungdom och vuxen. Utformningen av lokalerna i det nyrenoverade biblioteket har inspirerats av teman i Tranströmers poesi. Biblioteket erbjuder ett stort urval av Tranströmers samlade verk, texter om hans författarskap samt översättningar på flera språk.</p>

        <p>Hans syn på att få sitt barndoms bibliotek uppkallat efter sig stämmer överens med hur han såg på sig själv som poet. Han var aldrig särskilt förtjust i uppmärksamhet eller att försöka leva upp till förväntningar på hur en poet skulle vara. Han ville vara privat, och hade ju också sitt förvärvsarbete som psykolog parallellt med skrivandet, och han värnade sin integritet, <span className="italic">“jag som älskar att ströva och försvinna i mängden, en bokstav T i den oändliga textmassan.”</span> skriver han i dikten <span className="italic">Svar på brev, 1983,</span> i samlingen <span className="italic">Det vilda torget</span>.</p>

        <p>År 2000 flyttade Tomas och hans hustru Monica Tranströmer tillbaka till Södermalm efter många år i Västerås. Tomas Tranströmer är begravd på närbelägna Katarina kyrkogård.</p>

        <section className="poem">
          <p>
            Fantastiskt att känna hur min dikt växer<br />
            medan jag själv krymper.<br />
            Den växer, den tar min plats.<br />
            Den tränger undan mig.<br />
            Den kastar mig ur boet.<br />
            Dikten är färdig.
          </p>
        </section>

        <p>ur dikten <span className="italic">Morgonfåglar</span>, ur diktsamlingen <span className="italic">Klanger och spår, 1966</span>.</p>

        <p className="bold">Välkommen till Tranströmerbiblioteket!</p>
        <div className="Box--green">
          <IconNote/>
          <p>Tranströmer spelar <span className="italic">Frank Bridge: A Vigil</span></p>
        </div>
      </article>,
    image: {
      src: 'https://transtromerssodermalm.stockholm.se/images/medborgarhuset_Lennart_af_Petersens_sthlms_stadsmuseum.jpg',
      caption: 'Plaskdamm i Björns Trädgård, 1940-1949',
      ref: 'Fotograf: Petersens, Lennart af (1913-2004) — 1940-1949 — Objekt-ID: Stockholms stadsmuseum, Fotonummer FA 37150'
    },
    audio: {
      src: 'https://transtromerssodermalm.stockholm.se/soundfiles/sound-7.mp3',
    },
    pos: {
      lat: '59.31464400894913',
      long: '18.072020710443073',
    },
    checked: false
  },
];
