import s from './LocationCard.module.scss';
import Modal from '../../../components/Modal/Modal';
import { useParams, useLocation, Link, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useModal } from '../../../hooks/modalHook';
import { PROJECTS } from '../../../constants/projects';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AudioPlayer from 'react-h5-audio-player';
import { ReactComponent as IconBack } from '../../../assets/icons/icon-back.svg';
import { ReactComponent as IconFwd } from '../../../assets/icons/icon-fwd.svg';
import { ReactComponent as IconPause } from '../../../assets/icons/icon-pause.svg';
import { ReactComponent as IconPlay } from '../../../assets/icons/icon-play.svg';
import { ReactComponent as IconArrow } from '../../../assets/icons/icon-left-arrow.svg';
import MapLayout from '../../../layouts/MapLayout/MapLayout';

const LocationCard = () => {
  const { id } = useParams();
  const location = useLocation();
  const { isVisible, toggleModal } = useModal();
  const [isActive, setActive] = useState(false);
  const [showButton, setShowButton] = useState(false);

  /* After load, toggle modal */
  useEffect(() => {
    toggleModal();
  }, []);

  /* Redirect to overview if no location.state */
  if (!location.state) {
    return (<Redirect to = '/overview' />);
  }

  const { from } = location.state;
  const { title, address, image, description, audio } = PROJECTS.find(
    (p) => id === p.id,
  );

  /* Pass next ID back to overview through state */
  let overviewNext = id;
  overviewNext++;

  /* Trigger popup when audio is finished */
  const Popup = () => {
    setActive(!isActive);
    setShowButton(true);
    PROJECTS.find(x => x.id === id)['checked'] = true // Set current ID to checked
  };

  return (
    <MapLayout redPin={id}>
      <div className={s.back}>
        <Link
          className="bold is-flex-center"
          to={{
            pathname: "/overview",
            state: { from: from, next: overviewNext}
          }}
        >
          <IconArrow />&nbsp;Tillbaka
        </Link>
      </div>
      <Modal show={isVisible} onClose={toggleModal}>
        <div className={s.cardWrapper}>
            <div className="cardInner">
              <div className={s.cardHeading}>
                <div className="Pin--container">
                  <p>{id}</p>
                </div>
                <div className={s.cardHeadingText}>
                <h3 className={s.title}>{title}</h3>
                  <p>{address}</p>
                </div>
              </div>
              <LazyLoadImage
                alt="project-img"
                src={image.src}
                effect="blur"
                width="100%"
                wrapperClassName={s.image}
              />
              <div className={s.cardBody}>
                <p className={s.imgCaption}>{image.caption} *</p>
                <div className={s.description}>{description}</div>
                <p className={s.imgRef}>* {image.ref}</p>
              </div>
            </div>
          </div>
          <AudioPlayer
            src={audio.src}
            onEnded={e => Popup()}
            autoPlayAfterSrcChange={false}
            progressJumpStep='15000'
            customIcons={{
              play: <IconPlay />,
              pause: <IconPause />,
              rewind: <IconBack />,
              forward: <IconFwd />
          }}
          />
        
        <div className={`${s.overlay} ${isActive ? `${s.isActive}` : ''}`}>
          <div className={s.innerText}>
            <h4>Nu har du lyssnat klart!</h4>
            <div className={s.innerTextSmall}>
              Gå tillbaka till översikten för att välja nästa plats.
            </div>
            <div className={`is-button ${showButton ? 'is-active' : ''}`}>
              <Link
                to={{
                  pathname: "/overview",
                  state: { from: from, next: overviewNext}
                }}
                className="btn is-red"
              >
                Tillbaka
              </Link>
            </div>
          </div>
        </div>
        </Modal>
      </MapLayout>
  );
};

export default LocationCard;
