import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
window.isPrev = 1
const Root = () => {
  return (
      <App />
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

reportWebVitals();
serviceWorkerRegistration.register();
