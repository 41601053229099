import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
//modals
import LocationCard from '../scenes/Locations/LocationCard/LocationCard';
//scenes
const Home = lazy(() => import('../scenes/Home/Home'));
const Intro = lazy(() => import('../scenes/Intro/Intro'));
const Locations = lazy(() => import('../scenes/Locations/Locations'));
const Overview = lazy(() => import('../scenes/Overview/Overview'));

export const routes = {
  HOME: '/',
  INTRO: '/intro',
  LOCATIONS: '/locations',
  LOCATION: '/location/:id',
  OVERVIEW: '/overview',
};

const BaseRoutes = () => {
  const location = useLocation();
  const background = location.state?.background;

  return (
    <>
      <Switch location={background || location}>
        <Route path={routes.HOME} exact component={Home} />
        <Route path={routes.INTRO} component={Intro} />
        <Route path={routes.LOCATIONS} component={Locations} />
        <Route path={routes.LOCATION} component={LocationCard} />
        <Route path={routes.OVERVIEW} component={Overview} />
      </Switch>

      {background && (
        <Route path={routes.LOCATION} component={LocationCard} />
      )}
    </>
  );
};

export default BaseRoutes;
