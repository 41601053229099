import s from './Modal.module.scss';
import { useState } from 'react';
import ToggleTextIcon from '../../assets/icons/icon-text.svg';
import ToggleMapIcon from '../../assets/icons/icon-map.svg';

const Modal = ({ children }) => {
  const [isActive, setActive] = useState(true);

  const toggleClass = (e) => {
    setActive(!isActive);
  };

  return (
    <div className={`${s.container} ${isActive ? `${s.isActive}` : ''}`}>
      <div className={s.inner}>
        <div className={s.toggleOuter}>
          <button className={s.toggle} onClick={toggleClass}>
            {!isActive && <span>Visa text <img src={ToggleTextIcon} alt="Textikon"/></span>}
            {isActive && <span>Visa karta <img src={ToggleMapIcon} alt="Kartikon"/></span>}
          </button>
        </div>
        <div className={s.modal}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
