import s from './MapLayout.module.scss';
import { useEffect } from 'react';
import { PROJECTS } from '../../constants/projects';
import L from 'leaflet';

const MapLayout = ({ children, redPin }) => {
  const container = L.DomUtil.get('map');
  if (container != null) {
    container._leaflet_id = null;
  }

  let map = null;

  useEffect(() => {
    geoFindMe();
  });

  const geoFindMe = () => {

    map = new L.Map('map');
    initializeMap();

    function initializeMap() {
      map.setView([59.308865, 18.07315], 14);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        // tileSize: 256,
        // zoomOffset: -1,
      }).addTo(map);

      const locations = [];
      const markers = [];

      PROJECTS.forEach(project => {
        locations.push([project.pos.lat, project.pos.long])
      })
    
      locations.forEach(function (child, i) {
        var id = locations.indexOf(child);
        id++;

        var customIcon = L.divIcon({
          className: 'Pin--container on-map is-blue',
          html: '<button>' + id + '</button>',
          iconAnchor: [10, 40],
          popupAnchor: [10, -40]
        });
      
        const marker = new L.marker([locations[i][0], locations[i][1]], { icon: customIcon })
          .addTo(map);

        if (`${id}` === redPin) {
          L.DomUtil.removeClass(marker._icon, 'is-blue');
        }

        markers.push(marker);
      });

      /* set view to all markers */
      const fg = L.featureGroup(markers).addTo(map);
      map.fitBounds(fg.getBounds());
    }

  }

  return (
    <div className={s.layout}>
      <div className={s.container}>
        <div id="map"></div>
        {children}
      </div>
    </div>
  );
};

export default MapLayout;
